import DividerSvg from '@/svg/divider.svg';

const Divider = () => (
  <div className="w-full flex items-center gap-1">
    <div className="flex-1 bg-gradient-to-r from-transparent to-primary-300 h-[3px] rounded-full" />
    <DividerSvg className="" />
    <div className="flex-1 bg-gradient-to-l from-transparent to-primary-300 h-[3px] rounded-full" />
  </div>
);

export default Divider;
